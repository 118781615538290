import {createRouter, createWebHashHistory} from 'vue-router'
import {sysRouteList, sysRoutes} from "@/router/sysRouter";
import {frontRouter, frontRouterList} from "@/router/frontRouter";
import {cWebUrl, loginUrl} from "@/enumbag/StyleEnum";

const routes = [...sysRoutes, ...frontRouter];

// 添加一个捕获所有路由的路由，用于处理错误
routes.push({ path: '/:catchAll(.*)', name: 'error', component: () => import('../views/auth/home/page/tool/Error') });


const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

// 配置路由守卫
router.beforeEach((to, from, next) => {
  // console.log('路由', to.path)
  let token = window.localStorage.getItem('token');
  let user = JSON.parse(window.localStorage.getItem('account'));
  // 是否是去登录
  if (to.path.includes(loginUrl) || to.path.includes('/auth/login')) {
    next(); // 如果要去登录，直接放行
  } else if (sysRouteList.includes(to.path)) {
    // 访问后台路由 登录状态->后台用户放行反正跳转前台首页 未登录->跳转后台登录页
    if (token && user) {
      if (user.userType === 1) {
        next();
      } else {
        next(cWebUrl)
      }
    } else {
      next(loginUrl)
    }
  } else if (frontRouterList.includes(to.path)) {
    // 访问前台路由 登录状态->放行 未登录->跳转前台登录页
    if (token && user) {
      next()
    } else {
      next('/auth/login');
    }
  } else {
    next();
  }
})
