// src/langurage/en_US.js

/**
 * 定义英文语言包对象
 */
export default {
    // Common Language Pack
    common: {
        // Dialog
        dialog: {
            title: 'Prompt',
            defaultTitle: 'Default Box',
            addTitle: 'Add Box',
            editTitle: 'Edit Box',
            detailTitle: 'Detail Box',
            selectTitle: 'Selection Box',
            preview: 'preview',
            deleteTitle: 'Delete Data',
            deleteTips: 'This operation will permanently delete the data. Do you want to continue?',
            inquireTips: 'Ticket personnel are initiating this Ticket process. Do you agree?',
            permissionTitle: 'Modify Role Permissions',

            replyTitle: 'Reply Box',
            eventTitle: 'New Event',
            fillInLogisticsTitle: 'Fill in Logistics',
        },
        message: {
            tipsTitle: 'Prompt',
            successTitle: 'Success',
            success: 'Operation executed successfully',
            errorTitle: 'Failure',
            error: 'Operation execution failed',
            unknownTitle: 'Unknown',
            unknown: 'Unknown status. Please contact the administrator',

            addSuccess: 'Added successfully',
            addError: 'Addition failed',
            updateSuccess: 'Modified successfully',
            updateError: 'Modification failed',
            deleteSuccess: 'Deleted successfully',
            deleteError: 'Deletion failed',

            networkError: 'Network error. Please try again later!',
            uploadError: 'Uploaded avatar image must be in JPG/PNG format!',
            uploadSizeError: 'Uploaded image size cannot exceed 2MB!',
            uploadNumError: 'File upload exceeds limit!',

            uploadFileSizeError: 'The size of the uploaded file cannot exceed 20MB!',
            uploadFileNumError: 'Current upload limit Select 6 files, exceed the limit!',
            uploadFileFormatErr: 'The format of the uploaded file is not allowed',

            sortNumber: 'Sorting value must be a number',
            dictKeyAndValue: 'Please enter dictionary key and value',
            isDefault: 'Please switch "Non-main email" to "Main email" to automatically unselect other email\'s main status',

            copySuccess: 'Copy successful',

            remoteLogin: 'Remote Login',
            remoteLoginMsg: 'The account has been logged in elsewhere, if not myself login, please change the password as soon as possible!',
            imgUploadMgs: 'Click to upload the picture',
        },
        button: {
            login: 'Login',
            register: 'Sign In',

            search: 'Search',
            reset: 'Reset',
            resetLogin: 'Reset The Login Form?',
            add: 'Add',
            save: 'Save',
            detail: 'Detail',
            edit: 'Edit',
            delete: 'Delete',
            modify: 'Modify',
            confirm: 'Confirm',
            back: 'Back',
            goBack: 'Go Back',
            cancel: 'Cancel',
            select: 'Select',
            permission: 'Permissions',
            progress: 'Progress',
            record: 'Invoice',
            care: 'Transfer',
            allocation: 'Allocate',
            appoint: 'Assign Ticket',
            receive: 'Receive Ticket',
            transfer: 'Transfer Ticket',
            transfering: 'Transferring',
            cancelTransfer: 'Cancel Transfer',
            fillIn: 'Fill In',
            check: 'View',
            checkAll: 'View All',
            after: 'Event',
            reply: 'Reply',
            close: 'Close',
            ReplayAndClose: 'Replay and Close',
            end: 'End',
            submit: 'Submit',
            addCost: 'Add Cost',
            accept: 'Accept',
            refuse: 'Refuse',
            option: 'Option',
            selectAll: 'Select All',
            prev: 'Previous',
            sign: 'Sign',

            change: 'Change',
            continue: 'Continue',
            revoked: 'Revoked',
            share: 'Share',
            generate: 'Generate',
            copy: 'Copy',
            jump: 'Jump',
            restore: 'Restore',

            resetPass: 'ResetPass',

            read: 'Mark All as Read',

            fillInAccount: 'Fill in Account',
            checkAccount: 'Check Account',

            importExcel: 'Upload RMA Sheet',
            downloadExcel: 'Download RMA Sheet',
            goTicket: 'Submit a Support Ticket',

            uploadFile: 'Upload File',
            total: 'Total',
        },
        table: {
            noData: 'No data available',
            number: 'Number',
            operation: 'Operation',
            allocation: 'Allocate',
        },
        loadingText: 'Please wait, loading...',
        loginExpired: 'Login expired. Please log in again',
    },
    pullDown: {
        placeholder: 'Select',
        unknown: 'Unknown',
        noData: 'No Data',
        sex: {
            secret: 'Secret',
            man: 'Man',
            woman: 'Female',
        },
        userStatus: {
            enable: 'Incumbency',
            disable: 'Dimission',
        },
        daterange: {
            start: 'Start Date',
            range: 'to',
            end: 'End Date',
        },
        resourceType: {
            parent: 'Level one menu',
            page: 'Page Route',
            button: 'Action Button',
        },
        enable: {
            enable: 'Enable',
            disable: 'Disable',
        },
        messagePushTime: {
            goTime: 'Time Push',
            goPlus: 'Direct Push',
        },
        messageType: {
            complaint: 'Notice of Complaints',
            system: 'System notifications',
        },
        isDefault: {
            defaultMail: 'Main Email',
            notDefaultMail: 'Non-main Email',
        },
        afterStatus: {
            allocation: 'Distribution',
            pending: 'Pending',
            processing: 'Progress',
            completed: 'Ended',
            revoked: 'Revoked',
            maintain: 'Maintain',
        },
        // Translation
        expressageStatus: {
            processing: 'awaiting signature',
            completed: 'Sign for',

            accountNo: 'To be filled',
            accountYes: 'Fill out',
        },

        careofStatus: {
            processing: 'In Transfer',
            completed: 'Transfer Successful',
            refused: 'Transfer Rejected',
            cancel: 'Transfer Cancelled',
        },
        afterFromType: {
            privacy: 'Privacy Policy',
            disclaimer: 'Disclaimer',
            terms: 'Terms and Conditions',
        },
        result: {
            success: 'Success',
            error: 'Error',
        },
        setUp: {
            sys: 'System built-in',
            drop: 'Pull Down Data',
        },
    },
    excel: {
        importTitle: 'Import Data',
        download: 'Download Import Template',
        importTips: 'Please upload an Excel file',
        importError: 'File format is incorrect. Only xls/xlsx files are allowed!',

        dialog: 'Error Prompt for Importing Table',
        maxlog: 'The maximum size of a table file is 10M. This file exceeds the maximum value. You can import it in batches',
        label: 'Error Message',

        exportTitle: 'Export Table',
        spuFile: 'Product Information-',
    },
    // Settings
    system: {
        train: 'Column Settings',
        frame: 'Search Function',
    },
    // Translation
    elTag: {
        headerTitle: 'Support - iFi',

        mdTitle: 'Editor',
        editorTitle: 'Rich Text Editor',
        editorTip: 'Please enter your content...',

        confirmed: 'Confirmed',
        expired: 'Expired',
        expiredAff: 'Process Ended',
    },
    // File
    file: {
        uploadLabel: 'Upload File',
        uploadSidLabel: 'Receipt Included?',
        text: 'Drag files here or',
        textEm: 'Click to upload',
        textTip: 'You can upload up to 6 files, with a maximum size of 60MB each',

        fromLabel: 'Attachment',
        fromText: 'File',
    },


    // ------ Login Page / Home Framework / Home Page / Personal Center ------
    // Login Page Language Pack
    LoginView: {
        // title: 'KaiYun iFi Ticket Platform',
        // titleBox: 'WeCome: iFi Ticket ',
        // content: 'Kaiyun iFi Ticket platform focuses on solving the Ticket problems of the products purchased by customers, and carries out the process tracking record processing management platform',

        tab1: 'Account Password Login',
        login: 'Log In',
        account: 'Account',
        accountPlaceholder: 'Enter your account',
        password: 'Password',
        passwordPlaceholder: 'Enter your password',

        titleUrl: 'Navigation Link',
        clienteleAfter: 'Customer',
        officialMall: 'Franchise',
        officialWebsite: 'Support',
        afterPlatform: 'Document',
    },
    // Home Page Language Pack
    HomeView: {
        title: 'KaiYun iFi Ticket Platform',
        home: 'Home',
        language: {
            zh: '中文简体',
            en: 'English'
        },
        languageDialog: {
            title: 'Language Switch',
            zhContent: 'Switch language to Chinese. Are you sure to continue?',
            enContent: 'Switch language to English. Are you sure to continue?',
        },
        itemDropdown: {
            refresh: 'Refresh Page',
            close: 'Close Tab',
            closeLeft: 'Close Tabs to the Left',
            closeRight: 'Close Tabs to the Right',
            closeAll: 'Close All Tabs',
            closeOther: 'Close Other Tabs',
        },
        // 头像下拉菜单
        avatarDropdown: {
            profile: 'Profile',
            setCache: 'Refresh cache',
            clearCache: 'Clear Cache',
            logout: 'Log Out'
        },
        // 退出登录
        logOutDialog: {
            title: 'Log Out',
            logoutTips: 'Are you sure you want to log out?',
            logoutSuccess: 'Logged out successfully!',
            logoutError: 'Log out cancelled',
        },
        clearCache: 'Clearing cache, please wait',
        error: '404 Page Not Found, it seems lost, the page does not exist',
        iconTitle: 'Icon Library',
    },
    // Home Page Language Pack
    HomePageView: {
        user: {
            title: 'Welcome',
        },
        dataPresentation: {
            title: 'Data Presentation - Aftersales Orders in Progress',
            radio: {
                my: 'My',
                all: 'All',
            },
            proportion: 'Work Order Ratio - ',
            allProportion: 'All Orders Proportion',

            threeDayQuantity: '3-Day Processed Orders',
            threeDayQuantityRemark: 'Your 3-day (0-3) processed orders',
            sevenDayQuantity: '3-7 Day Processed Orders',
            sevenDayQuantityRemark: 'Your 3-7 day (=3<=7) processed orders',
            bigSevenDayQuantity: '7+ Day Processed Orders',
            bigSevenDayQuantityRemark: 'Your 7+ day (>7) processed orders',
            allQuantity: 'Total Processed Orders',
            allQuantityRemark: 'Total processed orders you are responsible for',

            allThreeDayQuantity: 'All 3-Day Processed Orders',
            allThreeDayQuantityRemark: 'All processed orders in the past three days',
            allSevenDayQuantity: 'All 3-7 Day Processed Orders',
            allSevenDayQuantityRemark: 'All processed orders in the past 3-7 days (=3<=7)',
            allBigSevenDayQuantity: 'All 7+ Day Processed Orders',
            allBigSevenDayQuantityRemark: 'All processed orders in the past 7+ days (>7)',
            allAllQuantity: 'Total Processed Orders',
            allAllQuantityRemark: 'Total processed orders',
        },
        chart: {
            title: 'Chart Statistics - Monthly Completed Work Orders',
            radio: {
                halfYear: 'Last Six Months',
                year: 'Last Year',
            },
            my: 'My Completed Work Orders',
            all: 'All Completed Work Orders',
        },
        activities: {
            title: 'Platform Notices and Announcements'
        },
        backLog: {
            title: 'My To-Do List',
            description: 'There is currently no data! Add a new item?',
            addButton: 'Add To-Do Item',
            enableTag: {
                pending: 'Pending',
                processing: 'In Progress',
                completed: 'Completed',
            },
            significanceTag: {
                mild: 'Low',
                general: 'Medium',
                important: 'High',
            },
            titleDialog: {
                addTitle: 'Add To-Do Item',
                updateTitle: 'Edit To-Do Item',
            },
            formDialog: {
                title: 'Title',
                titlePlaceholder: 'Enter a title, maximum length is 16 characters',
                enable: 'Status',
                enablePlaceholder: 'Select the status of your to-do item',
                significance: 'Importance',
                content: 'Content',
                contentPlaceholder: 'Enter the specific content of your to-do item',
            },
        }
    },
    // 主页样式抽屉
    StyHome: {
        title: 'Home Style',
        // 全局样式
        wholeDivider: 'Global Style',
        wholeTheme: 'Dark Theme',
        // 顶部栏样式
        headerDivider: 'Header Style',
        headerLogo: 'Logo Display: ',
        headerTitle: 'Title Display: ',
        headerBackgroundColor: 'Header Background Color: ',
        headerTextColor: 'Header Text Color: ',
        // 侧边栏样式
        menuDivider: 'Menu Style',
        menuSty: 'Menu Collapse: ',
        menuAccordion: 'Menu Accordion: ',
        menuBackgroundColor: 'Menu Background Color: ',
        menuTextColor: 'Menu Text Color: ',
        menuActiveTextColor: 'Menu Active Color: ',
        // 动态路由标签
        roueDivider: 'Route Tags',
        roueDiv: 'Dynamic Route Tags Hide: ',
        roueBackgroundColor: 'Route Tag Background Color: ',
        roueTextColor: 'Route Tag Text Color: ',
        roueActiveTextColor: 'Route Tag Active Color: ',

        // Component Page Style
        trainFrameTitle: 'Module Style',
        trainFrame: 'Column and Query Settings: ',
        trainFrameDefault: 'Settings (Restore Default): ',
        trainFrameDefaultButton: 'Default',

        // 提示 是否将系统样式恢复默认设置?
        tips: 'Are you sure you want to restore the system style to the default settings?',
    },
    // Personal Center Language Pack
    MyView: {
        userForm: {
            label: 'Profile',
            title: 'Welcome',
            account: 'Account',
            accountPlaceholder: 'Enter your account',
            nickName: 'Name (En)',
            nickNamePlaceholder: 'Enter your name (English)',
            name: 'Name (Zh)',
            namePlaceholder: 'Enter your name (Chinese)',
            sex: 'Gender',
            sexPlaceholder: 'Select your gender',
            role: 'Role',
            rolePlaceholder: 'Select your role',
            region: 'Region',
            regionPlaceholder: 'Select your region',
            phone: 'Phone',
            phonePlaceholder: 'Enter your phone number',
            identityCard: 'Agent Numbers',
            identityCardPlaceholder: 'Please enter your agent number',
            birthDate: 'Birth Date',
            birthDatePlaceholder: 'Select your date of birth',
            userStatus: 'Status',
            userStatusPlaceholder: 'Select user status',
            weixin: 'WeChat',
            weixinPlaceholder: 'Enter your WeChat ID',
            qq: 'QQ',
            qqPlaceholder: 'Enter your QQ ID',
            email: 'Email',
            emailPlaceholder: 'Enter your email',
        },
        updatePass: {
            label: 'Change Password',
            account: 'Account',
            password: 'Password',
            passwordPlaceholder: 'Enter your old password',
            newPassword: 'New Password',
            newPasswordPlaceholder: 'Enter your new password',
            newTowPasswordPlaceholder: 'Re-enter your new password',
            passwordLength: 'Password length should be between 6 and 20 characters',
            passwordInconsistent: 'Passwords entered do not match!',
        }
    },

    // ------ Basics: System Management ------
    // User Management
    UserView: {
        userData: 'UserData',
        getAvatar: 'Get Avatar',
        getAvatarIs: 'The user English name cannot be empty!',

        nameQuery: 'Name',
        nameQueryPlaceholder: 'Enter user name (Zh/En)',
        phoneQuery: 'Phone/Email',
        phoneQueryPlaceholder: 'Enter phone number or email',
        weixinQuery: 'WeChat/QQ',
        weixinQueryPlaceholder: 'Enter WeChat ID or QQ ID',

        account: 'Account',
        accountPlaceholder: 'Enter your account',
        password: 'Password',
        passwordPlaceholder: 'Enter your password',
        passwordLength: 'Password length should be between 6 and 20 characters',
        passwordMgs: 'The default password of the new user is the same as your account, and can be modified in the personal center later!',
        nickName: 'Name (En)',
        nickNamePlaceholder: 'Enter your Name (English)',
        name: 'Name (Zh)',
        namePlaceholder: 'Enter your Name (Chinese)',
        sex: 'Gender',
        sexPlaceholder: 'Select your gender',
        role: 'Role',
        rolePlaceholder: 'Select your role',
        region: 'Region',
        regionPlaceholder: 'Select your region',
        phone: 'Phone',
        phonePlaceholder: 'Enter your phone number',
        phoneLength: 'Phone number should be 11 characters long',
        identityCard: 'Agent Numbers',
        identityCardPlaceholder: 'Please enter your agent number',
        birthDate: 'Birth Date',
        birthDatePlaceholder: 'Select your birthdate',
        userStatus: 'User Status',
        userStatusPlaceholder: 'Select user status',
        weixin: 'WeChat',
        weixinPlaceholder: 'Enter your WeChat ID',
        qq: 'QQ',
        qqPlaceholder: 'Enter your QQ ID',
        email: 'Email',
        emailPlaceholder: 'Enter your email',
        emailError: 'Please enter a valid email address',
        regions: 'RegionScope',
        regionsPlaceholder: 'Select your region permissions',
        photoUrl: 'Avatar',
        updateUserName: 'Modified by',
        registerDate: 'RegistrationDate',
        updateDate: 'ModifiedDate',
        loginDate: 'LoginDate',
        loginNum: 'LoginCount',

        resetPassTxt: 'Confirm password reset? New password will be the same as the user account'
    },
    // Role Management
    RoleView: {
        name: 'Name',
        namePlaceholder: 'Enter role name',
        nameEn: 'Name (En)',
        nameEnPlaceholder: 'Enter role name (English)',
        remark: 'Role Description',
        remarkPlaceholder: 'Enter role description',
        createTime: 'Creation Time',
        roleMgs: 'Query is the basic permission displayed on the page, pay attention to the query authority level of the work order!',
    },
    // Menu Management
    MenuView: {
        parent: 'Parent Menu',
        parentPlaceholder: 'Enter parent menu, leave blank for top-level menu',
        name: 'Menu Name',
        namePlaceholder: 'Enter menu name',
        nameEn: 'Name (En)',
        nameEnPlaceholder: 'Enter menu name (English)',
        symbol: 'Symbol',
        symbolPlaceholder: 'Enter permission symbol, ignore for top-level menu',
        icon: 'Icon',
        iconPlaceholder: 'Select menu icon',
        resourceType: 'Type',
        href: 'Route',
        hrefPlaceholder: 'Enter route address, ignore for third-level button',
        sort: 'Sort',
        sortPlaceholder: 'Enter sort, must be a number',
        remark: 'Remark',
        remarkPlaceholder: 'Enter remark',
    },
    // Dictionary Management
    DictView: {
        // 查询条件
        dictNameQuery: 'Name',
        dictNameQueryPlaceholder: 'Enter dictionary name',

        // 表格
        dictCode: 'Code',
        dictCodePlaceholder: 'Enter dictionary code',
        dictName: 'Name',
        dictNamePlaceholder: 'Enter dictionary name',
        nameEn: 'Name (En)',
        nameEnPlaceholder: 'Enter dictionary name (English)',
        dictRemark: 'Remark',
        dictRemarkPlaceholder: 'Enter remark',
        createUser: 'Creator',
        createUserPlaceholder: 'Select creator',
        createTime: 'Creation Time',
        type: 'Type',
        typePlaceholder: 'Please select category',

        // 新增/修改/详情
        divider: 'Dictionary List Details',
        dictKey: 'Key',
        dictKeyPlaceholder: 'Enter dictionary key',
        keyEn: 'Key (En)',
        keyEnPlaceholder: 'Enter key (English)',
        dictValue: 'Value',
        dictValuePlaceholder: 'Enter unique dictionary value',
        dictDetailRemark: 'Remark',
        dictDetailRemarkPlaceholder: 'Enter dictionary remark',
        dictEnable: 'Enable',
    },
    Log: {

        name: 'Log Name',
        ip: 'IP Address',
        opAddress: 'opAddress',
        opBrowser: 'opBrowser',
        opOs: 'opOs',
        opTime: 'opTime',
        opUser: 'opUser',
        exeStatus: 'exeStatus',
        ok:'ok',
        err:'err',

        detailMsg: 'Log Record',
        className: 'className',
        methodName: 'methodName',
        paramJson: 'paramJson',
        resultJson: 'resultJson',

        errDetailMsg: 'Err Log',
        dateTime: 'Log Date',
        logPigeonhole: 'Log Pigeonhole',
        noLogPigeonhole: 'No abnormal archive logs are generated. Procedure',
        logNew: 'Latest Exception Log',

        user: 'Operator',
        userPlaceholder: 'Select operator',
        ipPlaceholder: 'Enter IP address',
        sourceDict: 'Source Module',
        sourceDictPlaceholder: 'Select source module',
        operate: 'Operation',
        operateTime: 'Time',
        result: 'Result',
        resultPlaceholder: 'Select operation result',

        spendTime: 'Spend',
        resultValue: 'Result Value',
        parameter: 'Parameters',
        method: 'Method',
        url: 'Request URL',

        logDetail: 'Log Details',
        no: 'No User',
    },
    myCentre: {
        user: 'Profile',
        resource: 'My Permissions',
        log: 'My Logs',
    },
    // Translation
    notice: {
        title: 'Platform Inbox',
        message: 'Inbox',

        tab: 'Inform',
        classify: 'Notification Classify',
        allNotice: 'All Notifications',
        messageList: 'Message List',
        myMessage: 'My Messages',
    },
    // System Configuration
    sysConfig: {
        category: 'Type',
        categoryPlaceholder: 'Please select a type',
        configKey: 'Key',
        keyPlaceholder: 'Please enter a configuration key',
        configValue: 'Value',
        valuePlaceholder: 'Please enter a configuration value',
        remarks: 'Notes',
        remarksPlaceholder: 'Please enter notes',
        createTime: 'Creation Time',
        updateTime: 'Modification Time',
        createUser: 'Creator',
        updateUser: 'Modifier',

        front: {
            msg: 'Note that the size of the foreground background picture (1800 x 400 is recommended) will affect the display, you can upload and save the effect in the foreground, you can make corresponding adjustments to the length and width of the picture!',
            title: 'Title',
            titleEn: 'Title / Eng',
            txt: 'Description',
            txtEn: 'Description / Eng',
            img: 'Background Picture',
            imgEn: 'Background Picture / Eng',
        },

        statistics: {
            title: 'Title work order statistics about the region work order data display associated with the region ID for binding.',
        },

        email: {
            alertTitle: 'Mail Title Configuration',
            title: 'Title',
            titlePlaceholder: 'Please enter the mail title (in Chinese)',
            titleEnPlaceholder: 'Please enter the mail title (in English)',
            titleTab: 'Subtitle',
            titleTabPlaceholder: 'Please enter the mail subtitle (in Chinese)',
            titleEnTabPlaceholder: 'Please enter the mail subtitle (in English)',
            alertTitleTow: 'Mail Content Template Configuration',
            titleDrawer: 'Mail Content Template Configuration',
            alertDrawer: 'Note: Braces are placeholders, and some content is dynamically concatenated with braces',
            labelDrawer: 'Template Content (in Chinese)',
            labelEnDrawer: 'Template Content (in English)',
        },
        emailMgs: {
            REGISTER_CONTENT: 'User Registration',
            SET_EMAIL_CONTENT: 'Mail Update',
            TICKET_SUBMIT: 'Work Order Submission',
            TICKET_END: 'Ticket Work Order Has Ended',
            TICKET_NEW: 'Work Order Process Update',
            ASSIGN_TICKET: 'Designated Work Order',
            CAREO_TICKET: 'Transfer Work Order',
            CLIENT_TICKET: 'Customer Service Reply',
            SERVICE_TICKET: 'Customer Reply Notification',
            SEND_VERIFY: 'Send Email Verification Code',
            SID_SUBMIT: 'Dealer Work Order Submission',
            SID_SERVICE: 'Dealer Message',
            SID_CLIENT: 'Dealer Handler Message',
            SID_END: 'Dealer Work Order End',
            SID_ASSIGN: 'Designated Dealer Work Order',
            SID_CAREO: 'Transfer Dealer Work Order',
        }
    },


    // ------ Client Page: Basic Management ------
    // Ticket Type Management
    problemView: {
        title: 'Type',
        titlePlaceholder: 'Enter Ticket type',
        titleEn: 'Type（En）',
        titleEnPlaceholder: 'Enter Ticket type in English',
        remarks: 'Description',
        remarksPlaceholder: 'Enter description of Ticket type',
        remarksEn: 'Description（En）',
        remarksEnPlaceholder: 'Enter description of Ticket type in English',
        icon: 'Icon',
        iconPlaceholder: 'Select icon',
        enable: 'Enable',
        enablePlaceholder: 'Select whether to enable',
    },
    // Message Announcement Management
    MessageView: {
        title: 'Title',
        titlePlaceholder: 'Enter notification title',
        titleEn: 'Title (En)',
        titleEnPlaceholder: 'Enter notification title in English',
        content: 'Content',
        contentPlaceholder: 'Enter notification content',
        contentEn: 'Content (En)',
        contentEnPlaceholder: 'Enter notification content in English',
        type: 'Type',
        typePlaceholder: 'Select notification type',
        pushTime: 'Schedule Push',
        pushName: 'Push Type',
        pushNamePlaceholder: 'Select push type',
        goTime: 'Push Time',
        startDate: 'Start Date',
        endDate: 'End Date',
        createUser: 'Creator',
        createTime: 'Creation Time',
        enable: 'Enable',
    },
    // Region Management
    RegionView: {
        name: 'Region (Zh)',
        namePlaceholder: 'Enter region/Chinese',
        code: 'Abbreviation',
        codePlaceholder: 'Enter abbreviation',
        enName: 'Region (En)',
        enNamePlaceholder: 'Enter region/English',
        remarks: 'Remark',
        remarksPlaceholder: 'Enter remark',
        enable: 'Enable',
    },
    // Email Management
    MailView: {
        buyDitchName: 'Email',
        buyDitchNamePlaceholder: 'Select email name',
        host: 'Server',
        hostPlaceholder: 'Enter server',
        username: 'Address',
        usernamePlaceholder: 'Enter address',
        password: 'Authorization Code',
        passwordPlaceholder: 'Enter authorization code',
        port: 'Port',
        portPlaceholder: 'Enter port',
        protocol: 'SMTP',
        protocolPlaceholder: 'Enter SMTP protocol',
        defaultEncoding: 'Encoding',
        defaultEncodingPlaceholder: 'Enter encoding',
        remarks: 'Remarks',
        remarksPlaceholder: 'Enter remarks',
        createUser: 'Creator',
        createTime: 'Creation Time',
        isDefault: 'Non-Primary',
    },
    // clientSideb
    clientSideb: {
        code: 'Code',
        codePlaceholder: 'Enter customer code',
        name: 'Name',
        namePlaceholder: 'Enter customer name',
        abbreviation: 'Abbreviation',
        abbreviationPlaceholder: 'Enter customer abbreviation',
        enable: 'Enable',
    },
    // issueRule
    issueRule: {
        kindDict: 'Category',
        kindDictPlaceholder: 'Select issue category',
        typeDict: 'Resource',
        typeDictPlaceholder: 'Please select a resource type',
        title: 'Title',
        titlePlaceholder: 'Enter Chinese/English title',
        titleZh: 'Title (zh)',
        titleZhPlaceholder: 'Enter Chinese title',
        titleEn: 'Title (en)',
        titleEnPlaceholder: 'Enter English title',
        contentZh: 'Content (zh)',
        contentZhPlaceholder: 'Enter Chinese content',
        contentEn: 'Content (en)',
        contentEnPlaceholder: 'Enter English content',
        remarks: 'Remark',
        remarksPlaceholder: 'Enter remarks',
        createUser: 'Created By',
        createTime: 'Created At',
        enable: 'Enable',
    },
    // 工单消息预设
    flowMessage: {
        category: 'category',
        categoryPlaceholder: 'Please select a default category',
        content: 'Default content',
        contentPlaceholder: 'Please enter the preset content',

        flowRemark: 'Private comment message reply is enabled!',
        remark: 'Private Note',
        remarkPlaceholder: 'Please enter the comment information for this message...',
    },
    // 币种资料
    currency: {
        selectMgs: 'Keyword ',
        selectMgsPlaceholder: 'Please enter keywords ',

        name: 'Name (zh)',
        namePlaceholder: 'Please enter name/Chinese ',
        nameEn: 'Name (en)',
        nameEnPlaceholder: 'Please enter name/English ',
        code: 'Code ',
        codePlaceholder: 'Please enter codes (e.g. GBP, CNY) ',
        symbol: 'Symbol',
        symbolPlaceholder: 'Please enter a symbol (e.g., ￥) ',
    },

    // ------ SPU Data: Product Management ------
    // Product Management
    SpuView: {
        spuNameQuery: 'Name',
        spuNameQueryPlaceholder: 'Enter product name / English name',

        spuCode: 'Material Code',
        spuCodePlaceholder: 'Enter product Material Code',
        spuName: 'Product',
        spuNamePlaceholder: 'Enter product name',
        nameEn: 'Name (En)',
        nameEnPlaceholder: 'Enter product name (English)',
        spuImg: 'Image',
        series: 'Series',
        seriesPlaceholder: 'Select product series',
        function: 'Function',
        functionPlaceholder: 'Select product function',
        specs: 'Specifications',
        specsPlaceholder: 'Enter product specifications',
        remarks: 'Description',
        remarksPlaceholder: 'Enter product description',
        remarksEn: 'Description (En)',
        remarksEnPlaceholder: 'Enter product description (English)',
        createUser: 'Creator',
        createUserPlaceholder: 'creator',
        createTime: 'Creation',
        enable: 'Status',
    },
    // Product Series Management
    SeriesView: {
        nameQuery: 'Name',
        nameQueryPlaceholder: 'Enter product series code or name',

        barCode: 'Series Code',
        barCodePlaceholder: 'Enter series code',
        name: 'Series Name',
        namePlaceholder: 'Enter series name',
        nameEn: 'Name (En)',
        nameEnPlaceholder: 'Enter series name (English)',
    },
    // Product Function Management
    FunctionView: {
        nameQuery: 'Name',
        nameQueryPlaceholder: 'Enter product function code or name',

        barCode: 'Function Code',
        barCodePlaceholder: 'Enter function code',
        name: 'Function Name',
        namePlaceholder: 'Enter function name',
        nameEn: 'Name (En)',
        nameEnPlaceholder: 'Enter function name (English)',
    },

    // ------ SPU Order: Ticket Order Management ------
    // Ticket Order Management
    AfterSpu: {
        cTicketMessage: 'Ticket Message',
        cUserMessage: 'Customer Ticket Problem',

        // Translation
        sourceDictName: 'Source',
        sourceDictNamePlaceholder: 'Please select the source type',

        afterOrderNumber: 'Ticket No',
        afterOrderNumberPlaceholder: 'Enter Ticket Order No.',
        createDay: 'Ticket Time',
        createDayUnit: 'Day',
        problemTitle: 'Type',
        problemTitlePlaceholder: 'Select Ticket Type',
        spuName: 'Product',
        spuNamePlaceholder: 'Select product Product',
        function: 'Function',
        functionPlaceholder: 'Select product function',
        series: 'Series',
        seriesPlaceholder: 'Select product series',

        familyName: 'Customer',
        familyNamePlaceholder: 'Enter customer name',
        summary: 'Summary',
        summaryPlaceholder: 'Enter Ticket problem summary',
        email: 'Email',
        emailPlaceholder: 'Enter customer email',
        spuSequence: 'Serial Number',
        spuSequencePlaceholder: 'Enter product Serial Number',
        buyTime: 'Date of purchase',
        buyTimePlaceholder: 'Purchase from',
        buyDitchName: 'Purchase From',
        buyDitchNamePlaceholder: 'Select purchase Purchase From',
        ditchRemarks: 'Remarks',
        ditchRemarksPlaceholder: 'Enter channel remarks',
        region: 'Country',
        regionPlaceholder: 'Select Country',
        prove: 'Proof of Purchase',
        provePlaceholder: 'Enter purchase proof',
        videoUrl: 'Video URL',
        videoUrlPlaceholder: 'Enter video URL',
        detail: 'Details',
        detailPlaceholder: 'Enter problem details',

        remarks: 'After Remarks',
        remarksPlaceholder: 'Enter order remarks',
        createTime: 'Time',
        createDate: 'Date',
        revampTime: 'Modification',
        finishTime: 'Completion',
        revokedTime: 'RevokedTime',
        disposeUser: 'Agent',
        alterUser: 'Transfer',
        disposeUserPlaceholder: 'Select',
        disposeRegion: 'Region',
        disposeRole: 'Role',
        afterStatus: 'Status',
        afterStatusPlaceholder: 'Select order status',

        // Button prompt messages
        receiveTxt: 'Are you sure you want to receive this Ticket order?',
        transferTxt: 'Are you sure you want to cancel the transfer of this Ticket order?',
        disposeUserError: 'Agent cannot be empty!',
        alterUserError: 'Transfer Agent cannot be empty!',

        // Specify / Transfer Title
        appointTitle: 'Carrying out assigned work orders',
        transferTitle: 'Work order referrals made',

        // Details / Modify / Add Page
        tagOne: 'Ticket Order - Product Information',
        tagTwo: 'Ticket Order - Agent',
        tagThree: 'Ticket Order - Details',

        // Progress
        flow: {
            title: 'Ticket: ',
            user: 'User: ',
            service: 'Customer Service: ',
            summarize: 'Summary: ',
            describe: 'Description: ',
            reference: 'Reference Image',

            // Logistics information entry in the process
            fillInLogistics: 'Fill in Logistics',
            ditchStatus: 'After Status',
            ditchStatusPlaceholder: 'Select Ticket status',
            logistics: 'Company',
            logisticsPlaceholder: 'Enter logistics company',
            logisticsNumber: 'Logistics Order',
            logisticsNumberPlaceholder: 'Enter logistics number',
            sendoutDate: 'Date',
            sendoutDatePlaceholder: 'Select dispatch date',
            remarks: 'Remarks',
            remarksPlaceholder: 'Enter logistics remarks',

            // New order event
            event: 'Create Ticket Order Event',
            ditchName: 'Type',
            ditchNamePlaceholder: 'Select Ticket type',
            newDitchStatus: 'Status',
            newDitchStatusPlaceholder: 'Select process status',
            newRemarks: 'Remarks',
            newRemarksPlaceholder: 'Enter process remarks',

            // Reply pop-up
            formTitle: 'Summary',
            formTitlePlaceholder: 'Enter summary',
            formDescribe: 'Description',
            formDescribePlaceholder: 'Enter description',
            uploadTitle: 'Image Upload',

            formTitleError: 'Please enter both summary and description!',
            endTag: 'This Ticket order has ended',
        },

        // Event Records and Costs
        affair: {
            event: 'Events and Records',
            eventFormTitle: 'Process Data Form',
            addEvent: 'Add Process Data',
            finishTxt: 'This operation will end the process. Continue?',

            cost: 'Costs and Expenses',
            costFormTitle: 'Costs and Expenses Form',
            addCost: 'Add Costs and Expenses',
            upCost: 'Modify Costs and Expenses',

            // Event Records
            ditchStatus: 'Status',
            ditchStatusPlaceholder: 'Select process status',
            expressage: 'Logistics',
            expressagePlaceholder: 'Enter logistics company',
            expressageNumber: 'Logistics',
            expressageNumberPlaceholder: 'Enter logistics number',
            numberPlaceholder: 'number',
            sendoutDate: 'Send/Receipt',
            expressageStatus: 'Status',
            expressageStatusPlaceholder: 'Select logistics status',

            affairNumber: 'Event Code',
            ditchName: 'Type',
            ditchNamePlaceholder: 'Select event type',
            endTag: 'This process has ended',

            sendoutDateNew: 'Send-out',
            sendoutDateNewPlaceholder: 'Select send-out date',
            signDate: 'Receipt',
            signDatePlaceholder: 'Select receipt date',
            freight: 'Amount',
            freightPlaceholder: 'Enter freight amount',

            // Costs and Expenses
            ditchNameStr: 'Type',
            ditchNameStrPlaceholder: 'Select cost type',
            money: 'Amount',
            moneyPlaceholder: 'amount',

            costType: 'Classification',
            ticketIncidents: 'Order Event - Costs and Expenses',
            ticketOther: 'Other Order - Costs and Expenses',
            supplement: 'This cost is for supplementary order expenses, unrelated to Ticket order events...',

            remarks: 'Remarks',
            remarksPlaceholder: 'Enter remarks',
            createTime: 'Creation Time',
        },

        // Share
        share: {
            title: 'Ticket Order - Share',
            shareTime: 'Period',
            threeDay: 'ThreeDays',
            sevenDay: 'SevenDays',
            thirtyDay: 'ThirtyDays',
            permanent: 'Permanently',
            shareLink: 'Share Link',
        },
    },
    // Ticket Order Transfer Records
    Careof: {
        tisTxt: 'The transferor and transferee cannot be the same!',
        afterOrderNumber: 'Ticket No',
        afterOrderNumberPlaceholder: 'Enter Ticket',
        problemTitle: 'Type',
        problemTitlePlaceholder: 'Select Ticket type',
        spuName: 'Product',
        spuNamePlaceholder: 'Select product Product',
        function: 'Function',
        functionPlaceholder: 'Select product function',
        series: 'Series',
        seriesPlaceholder: 'Select product series',

        familyName: 'Customer',
        familyNamePlaceholder: 'Enter customer name',
        buyDitchName: 'Purchase From',
        buyDitchNamePlaceholder: 'Select purchase Purchase From',
        region: 'Region',
        regionPlaceholder: 'Select region',
        agoUser: 'Transferor',
        agoUserPlaceholder: 'Select transferor',
        nowUser: 'Transferee',
        nowUserPlaceholder: 'Select transferee',
        careofStatus: 'Status',
        careofStatusPlaceholder: 'Select transfer status',

        summary: 'Summary',
        disposeUser: 'User',
        createTime: 'Transfer',
        revampTime: 'Processing',

        transferTxt: 'Are you sure you want to cancel the transfer of this Ticket order?',
        receiveTxt: 'Are you sure you want to accept the transfer of this Ticket order?',
        refuseTxt: 'Are you sure you want to reject the transfer of this Ticket order?',

        // Details
        afterTitle: 'Ticket Order Transfer',

        // Initiate Ticket Order - Transfer Application
        transferTitle: 'Initiate Ticket Order - Transfer Application',
        // Ticket Order - In Transfer
        transferingTitle: 'Ticket Order - In Transfer',
        // Ticket Order - Transfer Success (Order Agent has been changed)
        transferSuccessTitle: 'Ticket Order - Transfer Success (Order Agent has been changed)',
        // Ticket Order - Transfer Rejected (Transferee rejected the order transfer)
        transferRefusedTitle: 'Ticket Order - Transfer Rejected (Transferee rejected the order transfer)',
        // Ticket Order - Transfer Canceled by Transferee
        transferCancelTitle: 'Ticket Order - Transfer Canceled by Transferee',
        // Ticket Order - Transfer Result
        transferResultTitle: 'Ticket Order - Transfer Result',
        // Ticket Order - Transfer End
        transferEndTitle: 'Ticket Order - Transfer End',
    },
    // Chart Statistics
    SpuChart: {
        title: 'Telematics',
        monthSpu: {
            name: 'Product Monthly Chart',
            selectData: 'Month',
            selectDataPlaceholder: 'Select month',
            number: 'Count',
            numberPlaceholder: 'Enter count',
            numberList:{
                five: '5 items',
                ten: '10 items',
                fifteen: '15 items',
                twenty: '20 items',
            },
            afterQuantity: 'Ticket Quantity',

            daterange: 'Month',
            spu: 'Product Name',
        },
        monthAfter: {
            name: 'Monthly Ticket Chart',
            selectData: 'Year',
            selectDataPlaceholder: 'Select query year',
        },
        regionSpu: {
            name: 'Regional Ticket Chart',
            selectData: 'Year',
            selectDataPlaceholder: 'Select query year',
            region: 'Region',
            regionPlaceholder: 'Select region',
        },
    },
    // Ticket Dashboard
    Board: {
        // Translation
        boardTitle: 'Ticket processing work order data statistics',

        threeDayQuantity: 'Number of Tickets in 1 ≤ 3 Days',
        sevenDayQuantity: 'Number of Tickets in 3 ≤ 7 Days',
        bigSevenDayQuantity: 'Number of Tickets in 7 ≤ 15 Days',
        bigFifteenDayQuantity: 'Number of Tickets in 15 ≤ 30 Days',
        monthOneDayQuantity: 'Number of Tickets in 1 ≤ 2 Months',
        allQuantity: 'Processing the number of total workers',

        allProportion: 'Ticket Tickets: ',
    },


    // ------ Client View: Customer Ticket / Inquiry ------
    // Customer Ticket Process Form Page
    Clientele: {
        header: 'Ticket',

        afterProblem: 'Ticket Type',
        customer: 'Customer information',
        spuMessage: 'Ticket Details',
        ticketMessage: 'Ticket Information',

        selectSpu: 'Select',
        fillIn: 'Information',
        afterSubmit: 'Submission',

        // Ticket Order Fill-in Form
        spuName: 'Product',
        spuPlaceholder: 'Please select a Product',
        function: 'Product Function',
        series: 'Series',
        remarks: 'Description',
        cardHeader: 'Please fill in the Ticket order',

        name: 'First Name',
        namePlaceholder: 'Enter your first name',
        family: 'Last Name',
        familyNamePlaceholder: 'Enter your last name',
        email: 'Email',
        emailPlaceholder: 'Enter your email',
        emailError: 'Please enter a valid email address',
        spuSequence: 'Serial Number',
        spuSequencePlaceholder: 'Enter product Serial Number',
        buyTime: 'Date of Purchase',
        buyTimePlaceholder: 'Select purchase Date of Purchase',
        buyDitchName: 'Purchase From',
        buyDitchNamePlaceholder: 'Select purchase Purchase From',
        ditchRemarks: 'Remarks',
        ditchRemarksPlaceholder: 'Enter channel remarks',
        region: 'Region',
        regionPlaceholder: 'Select region',
        summary: 'Subject',
        summaryPlaceholder: 'Enter Ticket problem subject',
        detail: 'Details',
        detailPlaceholder: 'Enter problem details',
        prove: 'Proof of Purchase',
        provePlaceholder: 'Enter purchase proof',
        videoUrl: 'Video URL',
        videoUrlPlaceholder: 'Enter video link related to product issue',
        uploadTitle: 'Upload',
        andCheck: 'I agree to receive product information and promotions from iFi',
        check: 'Please check the above three items',
        checkMgs: 'Please read the Ticket policy and terms',
        emailNoNll: 'Email verification code cannot be empty!',

        // Ticket Order Submission Page
        afterSubmitTitle: 'Ticket Order Notification',

        afterSubmitSuccess: 'Submitted',
        afterSubmitSuccessButton: 'Go And See',

        afterSubmitError: 'Failed',
        afterSubmitErrorTips: 'Network exception, submission error, please try again later',

        // 客户 v2.0 更新
        iFiHeader: 'iFi Support',

        help: 'Get the assistance you need',
        contact: 'Please provide us with some specific information, and we will offer you applicable solutions. You can reach us through the following methods.',

        afterType: 'Ticket type',
        selectProduct: 'Select product',
        product: 'Ticket Product',
        afterOrder: 'Ticket',
        afterTypeTips: 'Please check the options available to you',

        afterResult: 'Result notification',

        verifyCode: 'verifyCode',
        verifyCodePlaceholder: 'Please enter verification code',
        clickCode: 'Click to refresh the code!',
        emailVerifyCode: 'Verification Code',
        emailVerifyCodePlaceholder: 'Please enter email verification code',
        CodeTime: 'Email verification code is valid within 30 minutes, expired need to re-obtain!',
        goEmailCode: 'Click to initiate email verification upon verification code approval!',
        getEmailCode: 'Confirm',
        emailNull: 'The mailbox is empty or the mailbox format is incorrect!',
        verifyCodeNull: 'Verification code cannot be empty!',
        emailNo: 'Email does not match the email address used for sending the verification code!',
        codeIsNull: 'Please obtain the verification code before submitting the work order!',

        firmwareStr: 'Is the firmware up to date?',
        mobileStr: 'Have you tried the device on another mobile/PC?',
        driverStr: 'Have you downloaded and installed the correct iFi driver(PC/Android only)?',
    },
    // Customer Query Ticket Order Page
    ClienteleQuery: {

        // help: 'Get the assistance you need',
        // contact: 'You can query the Ticket work order information through the Ticket work order number below, and contact us for the processing of Ticket work order, we will solve the problem for you as soon as possible, thank you for your support!',

        header: 'iFi Ticket Order Inquiry',
        afterOrderNumber: 'Ticket No',

        // Order Information
        spuName: 'Product',
        function: 'Function',
        series: 'Series',

        divider: 'After',
        problemTitle: 'Type',
        name: 'Customer',
        email: 'Email',
        spuSequence: 'Serial Number',
        buyTime: 'Purchase',
        buyDitchName: 'Purchase From',
        ditchRemarks: 'Remarks',
        region: 'Region',
        summary: 'Summary',
        detail: 'Details',
        afterStatus: 'Status',
        disposeUser: 'Agent',
        createTime: 'Date',

        // Logistics
        ditchStatus: 'After Status',
        ditchStatusPlaceholder: 'Select Ticket status',
        logistics: 'Company',
        logisticsPlaceholder: 'Enter logistics company',
        logisticsNumber: 'Logistics Order',
        logisticsNumberPlaceholder: 'Enter logistics number',
        sendoutDate: 'Date',
        sendoutDatePlaceholder: 'Select dispatch date',
        remarksPlaceholder: 'Enter logistics remarks',
        signDate: 'Receipt',
        signDatePlaceholder: 'Select receipt date',
        remarks: 'Remarks',

        flow: 'Ticket Order Process Progress',
        reference: 'Reference Image',
        fillInLogistics: 'Fill in Logistics',
        formTitle: 'Summary',
        formTitlePlaceholder: 'Enter summary',
        formDescribe: 'Description',
        formDescribePlaceholder: 'Enter description',
        uploadTitle: 'Upload',
        reply: 'Ticket Order Reply',

        // Tips
        queryNull: 'Query parameters are empty',
        finishTxt: 'This ticket will close. Do you wish to continue?',
        afterEnd: 'Ticket Order has ended',
        revokedTxt: 'This operation will revoke the Ticket order. Continue?',
        revokedEnd: 'Ticket Order has been revoked',

        // Ticket Process Box
        flowTitle: 'Ticket Process',

        waitDescription: 'Waiting for Ticket Assignment',
        waitDisposeDescription: 'Waiting for Customer Service to Process',
        disposeDescription: 'Customer Service Processing',
        endDescription: 'Ticket Ticket Ended',
        revokedDescription: 'User has Revoked the Ticket',

        finishTitle: 'Ticket Completed',
        finishDescription: 'Ticket Ticket Completed',

        // Translation
        refund: 'RefundAccount',
        refundNoll: 'Refund Account cannot be empty!',
        refundPlaceholder: 'Please fill in your refund account, e.g., China Merchants Bank / 878548569654785',

    },
    // 分享页面工单
    Share: {
        invalid: 'The link is invalid, please regenerate the Ticket work order sharing link',
        error: 'The link query is abnormal',
    },

    // ------ sidebAfter:经销商工单 ------
    // 经销商工单页面
    sidebAfter: {
        afterOrderNumber: 'Ticket No',
        sidebName: 'Distributor',
        sidebPlaceholder: 'Enter distributor code',
        sidebNamePlaceholder: 'Select distributor',
        email: 'Email',
        emailPlaceholder: 'Enter email',
        buyDitchName: 'Purchase From',
        buyDitchNamePlaceholder: 'Select purchase Purchase From',
        channel: 'Explain',
        channelPlaceholder: 'Enter channel description',
        regionName: 'Region',
        regionNamePlaceholder: 'Select region',
        salesManager: 'Sales man',
        salesManagerPlaceholder: 'Enter sales Sales man',
        summary: 'Fault Information',
        summaryPlaceholder: 'Enter problem Fault Information',
        remarks: 'Remarks',
        remarksPlaceholder: 'Enter order remarks',
        createTime: 'Creation Time',
        revampTime: 'Modification',
        finishTime: 'Completion',
        revokedTime: 'Revocation',
        disposeUser: 'Agent',
        disposeUserPlaceholder: 'Select Agent',
        afterStatus: 'Status',

        getSpu: 'Select Product',
        spuName: 'Product',
        spuNamePlaceholder: 'Please select a product',
        functionName: 'Function',
        seriesName: 'Series',
        quantity: 'Quantity',
        spuSequence: 'Serial Number',
        // spuSequencePlaceholder: 'Enter Serial Number, separate multiple with /',
        spuSequencePlaceholder: 'Please enter the serial number of the product',
        spuSequenceRepetition: 'Serial Number Repetition',
        buyTime: 'Date of Purchase',
        detailRemarks: 'Remarks',
        detailRemarksPlaceholder: 'Enter remarks',

        rules: {
            sideb: 'Distributor cannot be empty',
            email: 'Email cannot be empty',
            salesManager: 'Sales manager cannot be empty',
            region: 'Region cannot be empty',
            buyDitch: 'Purchase From cannot be empty',
            channel: 'Channel description cannot be empty',
            summary: 'Problem description cannot be empty',
        },

        message: {
            tableNull: 'The Ticket product list contains empty values, * fields are required!',
            spuSequenceErr: 'Serial Number and quantity are not consistent!',
        },

        tab: {
            flow: 'Orders and Progress',
            event: 'Events and Records',
            cost: 'Costs and Expenses',
            detail: 'Ticket Detail',

            title: 'Distributor Orders: ',
        },
        flow: {
            flowTitle: 'Ticket Processing Progress',
            flowText: 'Distributor',
            leaveTitle: 'Leave a Reply',
            leaveText: 'This order has ended. Thank you for your feedback',
        },
    },
    // 经销商工单的开放页
    fromSide: {
        choiceTxt: 'Please Select',
        sideTxt: 'Distributor Ticket Orders',
        selectSpu: 'Please select a product first',

        sideTitle: 'Distributor Ticket Orders',
        tableTitle: 'Expand Ticket Product Details',

        message: {
            spuRepetition: 'This product is repeated. You can add the Ticket quantity of its products!',
        },
    },
    // 自助服务开放页面
    issueClientele: {
        imgTitle: 'Get the assistance you need for your service support',
        imgTxt: 'Get the latest information about your iFi products, including self-service documents, product guarantees, after-sales maintenance information, etc., to solve the problems you encounter.',

        title: 'You can query the following service information',
        issue: {
            title: 'Self-service',
            contact: '- Visit our knowledge base and check common problem solutions',
            contact1: '- Committed to providing you with more convenient and efficient self-service',
            contact2: '- Meet your needs and solve your problems',
            button: 'Click to Query',
        },
        clientele: {
            title: 'Online Support',
            contact: '- Online technical service to solve problems encountered in product use',
            contact1: '- You can submit a work order based on the problems with the product',
            contact2: '- After-sales customer service will solve it for you in the first time',
            button: 'Click to Consult',
        },

        issueTitle: 'Search for more topics',
        issuePlaceholder: 'Search support',
        all: 'All',
        cardTitle: 'Support Documents',
        linkUrl: 'Related link',
    },

    // ------ 工单图表 ------
    chart: {
        productPeak: {
            week: 'Week: ',
            num: 'Number: ',
            currentWeek: 'Current Week',
            lastWeek: 'Last Week',
            nextWeek: 'Next Week',
        },
        ticketTime: {
            dateStr: 'Date Range: ',
            all: 'Ticket Total',
            avg: 'Average Time',
            allTime: 'Ticket Total Time',
            day: 'Day',
            single: 'Single',
        }
    },

    // ------ 前台登录 ------
    auth: {
        loginTitle: 'Welcome back to iFi',
        registerTitle: 'Join iFi and enjoy instantly',

        namePlaceholder: 'Please enter your nickname',
        accountPlaceholder: 'Please enter your email account',
        passwordPlaceholder: 'Please enter your account password',
        sendAuthVerifyMailPlaceholder: 'Enter verification code',
        sendButton: 'Send Code',
        userType: 'I am',
        userC: 'Customer',
        userB: 'Dealer',

        login: 'Login',
        register: 'Register',

        userDown: 'Profile',
        cloeDown: 'Clear Cache',
        logOutDown: 'Log Out',

        txt: 'or',
        getTxt: 'Already have an iFi account?',
        getLink: 'Go to login',
        setTxt: 'Have no account? ',
        setLink: 'Create one!',
        passTxt: 'Forgot your password?',
        passLink: 'Reset password',

        loginOk: 'Login successful',
        loginError: 'Login failed',
        loginNotError: 'User is not logged in, please log in first!',

        authDrawer: 'My Information',
        ticketDrawer: 'Ticket Record',
        userTypeLabel: 'User Type',
        userTag1: 'Backend User',
        userTag2: 'Customer',
        userTag3: 'Distributor',
        userTag: 'Unknown',
        switchBack: 'Switch Background',
    }

}
