import router from "@/router";
import {loginUrl} from "@/enumbag/StyleEnum";

/***
 * 获取本地缓存用户
 * @returns {any}
 */
export function getUserData() {
    return JSON.parse(window.localStorage.getItem('account'))
}

/**
 * 通过权限符获取缓存key aaa.select
 * returns aaa
 */
export function getKeepKey(symbolStr) {
    // 切割 . 获取数组
    return symbolStr.split('.')[0];
}

/**
 * 根据 权限符 判断用户是否有权限
 * @param symbolStr 权限符
 * @returns {boolean} 有权限_true | 无权限_false
 */
export function resourceIsUser(symbolStr) {
    try {
        let userInfo = JSON.parse(window.localStorage.getItem('account'));
        // console.log(userInfo.symbolList, userInfo.account + ': 的权限集合')
        if (userInfo.symbolList.includes(symbolStr)) {
            // console.log(`用户有权限: ${symbolStr}`);
            return true;
        } else {
            // console.log(`用户无权限: ${symbolStr}`);
            return false;
        }
    } catch (e) {
        router.push(loginUrl)
    }
}

/**
 * 根据 权限符 判断用户的查询终端的售后工单的权限
 * @returns {number} 1: 查询所有数据 | 2: 查询部分地区数据 | 3: 查询仅处理人为自己的数据
 */
export function resourceAndAfterIsUser() {
    try { // 权限一: 查询 工单 所有数据 afterSpu.select
        let oneSymbolStr = 'afterSpu.select';
        // 权限二: 查询 工单 部分地区数据 afterSpu.selectAndRegion
        let twoSymbolStr = 'afterSpu.selectAndRegion';
        // 权限三: 查询 工单 仅处理人为自己的数据 afterSpu.selectAndUser
        let threeSymbolStr = 'afterSpu.selectAndUser';

        let userInfo = JSON.parse(window.localStorage.getItem('account'));
        if (userInfo.symbolList.includes(threeSymbolStr)) {
            return 3;
        } else if (userInfo.symbolList.includes(twoSymbolStr)) {
            return 2;
        } else if (userInfo.symbolList.includes(oneSymbolStr)) {
            return 1;
        }
    } catch (e) {
        router.push(loginUrl)
    }
}

/**
 * 根据 权限符 判断用户的查询经销商的售后工单的权限
 * @returns {number} 1: 查询所有数据 | 2: 查询部分地区数据 | 3: 查询仅处理人为自己的数据
 */
export function resourceAndSideAfterIsUser() {
    try { // 权限一: 查询 工单 所有数据
        let oneSymbolStr = 'sidebAfter.select';
        // 权限二: 查询 工单 部分地区数据
        let twoSymbolStr = 'sidebAfter.selectAndRegion';
        // 权限三: 查询 工单 仅处理人为自己的数据
        let threeSymbolStr = 'sidebAfter.selectAndUser';

        let userInfo = JSON.parse(window.localStorage.getItem('account'));
        if (userInfo.symbolList.includes(threeSymbolStr)) {
            return 3;
        } else if (userInfo.symbolList.includes(twoSymbolStr)) {
            return 2;
        } else if (userInfo.symbolList.includes(oneSymbolStr)) {
            return 1;
        }
    } catch (e) {
        router.push(loginUrl)
    }
}
