import {resourceIsUser} from "@/util/resource";

/**
 * 自定义指令: 按钮权限
 */
export default {
    mounted(el, inding) {
        // 获取指令值 进行权限符判断在删除元素
        if (!resourceIsUser(inding.value)) {
            // 删除元素
            el.parentNode.removeChild(el)
        }
    },
};
